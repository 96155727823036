import React from 'react';
import Login from '../auth/Login';
import BackupLogin from '../auth/BackupLogin';

const Unauthorized = () => {
  return (
    <div>
      <div>Please login to continue.</div>
      <Login />
      <BackupLogin />
    </div>
  );
};

export default Unauthorized;
