import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useAuth0 } from '../auth/authprovider';

const CopyTokenToClipboard = () => {
  const { getTokenSilently } = useAuth0();
  const [buttonText, setButtonText] = useState(
    'Click to Copy Access Token to Clipboard'
  );

  const copyTokenToClipboard = async () => {
    const token = await getTokenSilently();
    navigator.clipboard.writeText(token).then(
      () => {
        setButtonText('Copied!');
      },
      () => {
        setButtonText('Could not access your clipboard');
      }
    );
  };

  return (
    <Button size="sm" onClick={() => copyTokenToClipboard()}>
      {buttonText}
    </Button>
  );
};

export default CopyTokenToClipboard;
