import React from 'react';

import LayoutWithoutCheckout from '../../components/layout/layoutwithoutcheckout';
import Seo from '../../components/layout/seo';

import AuthOnly from '../../components/auth/authonly';
import MembersIndex from '../../components/members';
import MembersApiProvider from '../../webapi/MembersApi';

const MembersPage = () => {
  return (
    <LayoutWithoutCheckout>
      <Seo title="Members" />
      <AuthOnly>
        <MembersApiProvider>
          <MembersIndex />
        </MembersApiProvider>
      </AuthOnly>
    </LayoutWithoutCheckout>
  );
};

export default MembersPage;
