import React, { Fragment, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { useAuth0 } from '../auth/authprovider';

const Login = () => {
  const [step, setStep] = useState('request');
  const { login, passwordlessStart } = useAuth0();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
  } = useForm();

  const onSubmit = (values) => {
    login(values.phone, values.verificationCode, 'sms', (err) => {
      if (err) {
        setError('verificationCode', err.code, err.description);
        console.error('unknown error:', err);
      }
    });
  };

  const onRequestCode = () => {
    const values = getValues();

    const phone = values.phone;
    passwordlessStart(phone, (err) => {
      setStep('verify');
    });
  };

  return (
    <Fragment>
      <h1>Login</h1>
      <br />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="loginPhone">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Phone Number</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="phone"
              placeholder="Phone Number"
              name="phone"
              required
              disabled={step === 'verify'}
              {...register('phone')}
            />
          </InputGroup>
        </Form.Group>
        <Button
          variant="primary"
          onClick={onRequestCode}
          data-test-id="login-request-code"
          block
          style={{
            display: step === 'request' ? 'block' : 'none',
          }}
        >
          Request Code
        </Button>
        <Form.Group
          controlId="loginCode"
          style={{
            display: step === 'verify' ? 'block' : 'none',
          }}
        >
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Code</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="text"
              placeholder="Login Code"
              name="verificationCode"
              required
              {...register('verificationCode')}
            />
          </InputGroup>
          <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
            {errors?.verificationCode?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Button
          variant="primary"
          type="submit"
          data-test-id="login-submit-code"
          block
          style={{
            display: step === 'verify' ? 'block' : 'none',
          }}
        >
          Login
        </Button>
      </Form>
    </Fragment>
  );
};

export default Login;
