import React, { Fragment } from 'react';
import { createIntl } from 'gatsby-plugin-react-intl';
import differenceInDays from 'date-fns/differenceInDays';

import { formatDeliverySlot } from '@/helpers/payload';
import NavBar from './navbar';
import CopyTokenToClipboard from './copytokentoclipboard';
import { FULL_MEMBERS_API } from '../../webapi/MembersApi';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../layout/loadingspinner';

const MembersIndex = () => {
  const { loading, data, error } = useQuery(FULL_MEMBERS_API);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <Fragment>Something went wrong... please reload the page.</Fragment>;
  }

  const { deliveryDate, deliveryTimeSlot } = formatDeliverySlot(
    createIntl({ locale: 'en' }),
    data.bikeDelivery
  );
  const remainingDays = differenceInDays(
    new Date(data.bikeDelivery.start),
    new Date()
  );

  return (
    <>
      <NavBar />
      <div>Hi {data.account.firstname}!</div>
      <br />
      <h2>{remainingDays} days remaining!</h2>
      <br />
      Your bike will be delivered to the following address
      <br />
      <b>{data.bikeDelivery.address}</b>
      <br />
      on the <b>{`${deliveryDate} (${deliveryTimeSlot})`}</b>
      .
      <br />
      <br />
      This is what you get:
      <br />
      <b>{data.bikeDelivery.model}</b>
      <br />
      <br />
      <CopyTokenToClipboard />
      <br />
      <br />
      <pre>{JSON.stringify(data, null, 2)}</pre>
      <br />
    </>
  );
};

export default MembersIndex;
