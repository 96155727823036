import React from 'react';
import { useAuth0 } from '../auth/authprovider';
import { Button } from 'react-bootstrap';

const NavBar = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
    <div className="navbar">
      {!isAuthenticated && (
        <Button size="sm" onClick={() => loginWithRedirect({})}>
          Log in
        </Button>
      )}

      {isAuthenticated && (
        <Button size="sm" onClick={() => logout()}>
          Log out
        </Button>
      )}
    </div>
  );
};

export default NavBar;
