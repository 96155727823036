import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  gql,
} from '@apollo/client';
import { setContext } from '@apollo/link-context';
import React from 'react';
import fetch from 'cross-fetch';

import { useAuth0 } from '../components/auth/authprovider';
import Unauthorized from '../components/members/unauthorized';
import LoadingSpinner from '../components/layout/loadingspinner';

const MembersApiProvider = ({ children }) => {
  const { loading, isAuthenticated, getTokenSilently } = useAuth0();

  const httpLink = createHttpLink({
    uri: process.env.GATSBY_GRAPHQL_MEMBER_URI,
    fetch,
  });

  const authLink = setContext(async () => {
    const token = await getTokenSilently();
    return {
      headers: {
        Authorization: token,
      },
    };
  });

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!isAuthenticated) {
    return <Unauthorized />;
  }

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default MembersApiProvider;

export const FULL_MEMBERS_API = gql(`
  query FullMembersApi {
    serviceVersion {
      version
    }
    memberStatus {
      authorized
      state
    }
    account {
      firstname
      lastname
      email
      phone
    }
    bikeStatus {
      name
      locked
      model
      latitude
      longitude
      updated
    }
    lock {
      keyEnc
      seed
      unlockCode
    }
    bikeDelivery {
      address
      start
      end
      model
      comment
      status
      onfleet_trackingurl
    }
    bikeSubscription {
      running
      cancelled
      trialing
      trialEnd
      nextInvoice
      price
      currency
      paymentMethod
    }
    fileUploadUrl {
      url
      path
      formFields
      expiry
    }
  }

`);
